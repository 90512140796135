<template>
  <b-modal
    :title="$t('organizations.edit.custom-fields.actions.add-field')"
    :visible="visible"
    hide-footer
    centered
    no-close-on-backdrop
    @change="$emit('update: change', false)"
  >
    <b-form>
      <b-spinner v-if="loading" small type="grow" class="d-block mx-auto text-primary"/>
      <template v-else-if="!todosJuntos || todosJuntos.length <= 0">
        <p class="mb-2 mt-1">
          {{ $t('members.edit.no-items') }}
        </p>
      </template>
      <b-form-group v-else label-for="customFields-name">
        <div v-for="(field, index) in todosJuntos" :key="index" class="font-weight-bold mb-2">
          <template>
            {{ translateTranslationTable($store.state.locale.currentLocale, field.name) }}
            <template v-if="field.required">
              *
            </template>
            <b-form-input
              v-if="field.type !== 'timestamp' && field.type !== 'date' && field.type !== 'boolean'"
              v-model="customFieldsResponses[field.key]"
              :type="getInputType(field.type)"
              :state="IsValidNumber(field.type, field.key)"
              class="mt-50"
            />
            <b-form-datepicker
              v-else-if="field.type === 'timestamp' || field.type == 'date'"
              v-model="customFieldsResponses[field.key]"
              class="mt-50"
              :locale="locale"
            />
            <b-form-checkbox
              v-else
              v-model="customFieldsResponses[field.key]"
              switch
              class="mt-50"
            />
            <b-form-invalid-feedback v-if="(field.type === 'integer' || field.type === 'decimal') && !IsValidNumber(field.type, field.key)" class="text-danger">
              <span v-if="field.type === 'integer'">{{ $t('backoffice.custom-fields.error-integer') }}</span>
              <span v-if="field.type === 'decimal'">{{ $t('backoffice.custom-fields.error-decimal') }}</span>
            </b-form-invalid-feedback>
          </template>
        </div>
      </b-form-group>
      <b-button
        v-if="todosJuntos && todosJuntos.length > 0 && !loading"
        variant="primary"
        type="submit"
        @click.prevent="addCustomField"
      >
        {{ $t('organizations.edit.custom-fields.actions.save') }}
      </b-button>
    </b-form>
  </b-modal>
</template>
<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

export default {
  name: 'CustomfieldsModal',
  mixins: [ToastNotificationsMixin],
  model: {
    prop: 'visible',
    event: 'update: change',
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    visible: Boolean,
    morphType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      customFieldsResponses: {},
      loading: false,
      customFields: [],
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    isDisabled() {
      return !this.IsValidNumber;
    },
    cFields() {
      return this.$store.getters.customFields;
    },
    prueba() {
      const valuea = { value: ' ' };
      const communityFields = this.customFields.map((object) => ({ ...object, value: ' ' }));
      return communityFields;
    },
    todosJuntos() {
      const propCustomFields = this._props.item?.custom;
      if (this.customFields && this.customFields.length > 0) {
        const communityFields = this.customFields?.map((x, index) => {
          if (propCustomFields) {
            propCustomFields.map((item) => {
              if (item.key === x.key) {
                this.customFieldsResponses[x.key] = item.value;
              }
            });
          }
          return { ...x };
        });
        const juntos = [...communityFields];
        return juntos;
      }
      return propCustomFields;
    },
  },
  async created() {
    this.loading = true;
    await this.fetchCustomFields();
    this.customFields = this.cFields;
    this.loading = false;
  },
  methods: {
    async fetchCustomFields() {
      await this.$store.dispatch('fetchCustomFields', { 
        modelType: this.morphType 
      });
    },
    async addCustomField() {
      this.$emit('update: change', false);
      let response;
      try {
        response = await this.$store.dispatch('AddCustomFields', {
          modelKey: this.item.key,
          modelType: this.morphType,
          customKey: Object.keys(this.customFieldsResponses),
          customField: Object.values(this.customFieldsResponses),
        });
        this.$emit('profile-updated');
        this.$emit('profileUpdated', this.customFieldsResponses);
        await this.fetchCustomFields();
        this.notifySuccess(this.$t('success-message.general-success-edit'));
      } catch (error) {
        this.notifyError(this.$t('error-message.general-error'));
      }
      return response;
    },
    translateTranslationTable,
    isSame(item) {
      return item?.map((item) => item === this.morphType).length > 0;
    },
    getInputType(type) {
      const types = {
        string: 'type',
        text: 'type',
        integer: 'number',
        decimal: 'number',
        number: 'number',
      };
      return types[type];
    },
    IsValidNumber(type, key) {
      if (this.customFieldsResponses[key]) {
        if (type === 'integer') {
          return this.customFieldsResponses[key] % 1 == 0;
        }
        if (type === 'decimal') {
          return this.customFieldsResponses[key] % 1 != 0;
        }
        return null;
      }
      return null;
    },
  },
};
</script>
