<template>
  <b-button-close class="button-close" @click="$emit('click', $event)">
    <b-icon-x />
  </b-button-close>
</template>

<script>
import { BButtonClose, BIconX } from 'bootstrap-vue';

export default {
  name: 'ButtonClose',
  components: { BButtonClose, BIconX },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.button-close {
  @include button-variant($secondary, $white);
  border-radius: 50%;
  outline: none !important;
}
</style>
