<template>
  <p :class="textStyle" :style="{ fontSize: fontSize }">
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'TextResizable',
  props: {
    text: {
      type: String || Number,
      required: true,
    },
    defaultFontSize: {
      type: Number,
      required: true,
    },
    defaultTextLength: {
      type: Number,
      required: true,
    },
    fontSizeToRest: { type: Number, required: true },

    minFontSize: {
      type: Number,
      default: 1.1,
    },
    textStyle: {
      type: String,
      default: '',
    },
    exponentialFontSizeRest: { type: Number, default: 0.115 },
  },
  data() {
    return {
      fontSize: `${this.defaultFontSize}rem`,
    };
  },
  watch: {
    text(newText) {
      this.changeFontSize(newText);
    },
  },
  created() {
    this.changeFontSize(this.text);
  },
  methods: {
    calculateFontSize(textLength) {
      const { minFontSize } = this;
      const defaultLength = this.defaultTextLength;
      const remainingLength = textLength - defaultLength;
      if (remainingLength > 0) {
        const toRest = this.fontSizeToRest * remainingLength - (remainingLength - 1) * this.exponentialFontSizeRest;
        const newFontSize = this.defaultFontSize - toRest;
        return `${Math.max(minFontSize, newFontSize)}rem`;
      }
      return `${this.defaultFontSize}rem`;
    },
    changeFontSize(text) {
      this.fontSize = this.calculateFontSize(text.length);
    },
  },
};
</script>
