import store from "@/store";
import { checkDates } from "@/store/collective/store-helpers";

// Functions for plans
export function fractionPrice(price, currency) {
  const { currentLocale } = store.state.locale;

  return `${parseFloat(price).toLocaleString(currentLocale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })} ${currency}`;
}

export function calculTotalPrice(total, discount) {
  if (discount) {
    return discount.isPercentage
      ? total - (total * discount.discount) / 100
      : total - discount.discount;
  }
  return total;
}

export function totalCard(item, discount, currency) {
  const currencySymbol = currency?.symbol || item.currencySymbol;
  if (item.total) {
    return fractionPrice(
      calculTotalPrice(item.total, discount),
      currencySymbol,
    );
  }
  return `0 ${currencySymbol}`;
}

// Functions for discounts
function checkRoles(roles) {
  const { loggedMemberRoles } = store.getters;
  const memberRolesKeys = [];
  loggedMemberRoles.forEach((role) => memberRolesKeys.push(role.key));
  return roles.some((role) => memberRolesKeys.includes(role.key));
}

function checkDiscounts(discounts, item) {
  let checkedDiscounts = discounts;
  // 1. Revisa el descuento por fecha
  checkedDiscounts = checkedDiscounts.filter((discount) => checkDates(discount.startDate, discount.endDate));
  if (checkedDiscounts.length > 0) {
    // 2. Revisa el descuento por rol de miembro y de descuento
    checkedDiscounts = checkedDiscounts.filter((discount) => checkRoles(discount.roles));
  }
  if (checkedDiscounts.length > 0) {
    // 3. Revisa quantas veces se puede utilizar el descuento
    checkedDiscounts = checkedDiscounts.filter((discount) => discount.maxNumberOfUses === null || discount.maxNumberOfUses === '' || discount.currentNumberOfUses < discount.maxNumberOfUses);
  }

  if (checkedDiscounts.length > 0) {
    // 4. Revisa quantas veces puede utilizar el descuento un mismo usuario
    checkedDiscounts = checkedDiscounts.filter((discount) => discount.maxNumberOfUsesPerMember === null || discount.maxNumberOfUsesPerMember === '' || discount.memberCurrentUses < discount.maxNumberOfUsesPerMember);
  }
  if (checkedDiscounts.length > 0) {
    // 5. Ordena el resultado final por el descuento más alto a aplicar
    checkedDiscounts.sort(
      (discount1, discount2) => calculTotalPrice(item.total, discount1)
        - calculTotalPrice(item.total, discount2),
    );
  }
  return checkedDiscounts;
}

export function discounts(items) {
  if (items.length > 0) {
    const goodDiscounts = {};
    items.forEach((item) => {
      if (item?.discounts?.length > 0) {
        let availableDiscounts = [];
        availableDiscounts = [...item.discounts];
        const finalDiscounts = checkDiscounts(
          availableDiscounts,
          item,
        );
        if (finalDiscounts.length > 0) {
          [goodDiscounts[item.key]] = finalDiscounts;
        }
      }
    });
    return goodDiscounts;
  }
  return null;
}
