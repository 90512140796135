<template>
  <div v-if="item">
    <bg-header-container />
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
      class="w-100 mr-lg-1"
    >
      <b-form @submit.prevent="handleSubmit(processPayment)">
        <!-- Content -->
        <div class="d-flex justify-content-between">
          <a
            variant="link"
            class="d-flex align-items-center pb-2"
            @click="goBackSubscriptions"
          >
            <feather-icon icon="ChevronLeftIcon" size="14" class="text-dark" />
            <h6 class="ml-50 text-dark m-0">
              {{ $t("onboarding.checkout.subscriptions") }}
            </h6>
          </a>
          <b-button class="mt-1 mb-2" type="submit" variant="primary">
            <span v-if="item.total != null && item.total.toFixed(1) === '0.0'">
              {{ $t("onboarding.checkout.continue") }}
            </span>
            <span v-else>
              {{ $t("onboarding.checkout.continue") }}
            </span>
            <feather-icon
              icon="ChevronRightIcon"
              size="14"
              class="ml-50 mr-0 text-light"
            />
          </b-button>
        </div>

        <b-row class="justify-content-md-center">
          <!-- Product information -->
          <b-col cols="12" :lg="item.total !== 0 ? '4' : '6'">
            <!-- ** ORDER ** -->
            <b-card no-body class="general-card">
              <b-card-body v-if="!isLoading">
                <div
                  :class="
                    showCodeInput || discountsToShow
                      ? 'pt-2 px-2'
                      : 'pt-2 px-2 pb-1'
                  "
                >
                  <!-- Product -->
                  <h4 class="mb-2">
                    1. {{ $t("onboarding.checkout.detail") }}
                  </h4>

                  <div
                    v-if="!item.total"
                    class="w-100 justify-content-center d-flex my-2"
                  >
                    <b-spinner variant="primary" class="mx-auto" />
                  </div>
                  <text-resizable
                    :text-style="'text-primary text-center h5 mt-2 mb-1'"
                    :text="price"
                    :default-font-size="3.5"
                    :default-text-length="12"
                    :font-size-to-rest="0.42"
                  />

                  <p
                    v-if="discounts.length > 0"
                    class="mb-50 mt-n2 text-center text-muted"
                  >
                  {{ $t("onboarding.checkout.previous-price") }}
                    <span style="text-decoration: line-through">
                      {{ item.total }} {{ item.currencySymbol }}
                    </span>
                  </p>
                  <h2 class="mb-50 text-center title">
                    {{ translate(item.name) }}
                  </h2>
                  <!-- Purchase element type -->
                  <div v-if="currentSpace.mainType" style="text-align: center;">
                    <span class="mb-50 text-center title badge badge-primary">
                      {{ currentSpace.mainType }}
                    </span>
                  </div>
                  <p
                    v-if="item.interval === 'month'"
                    class="text-muted text-center"
                  >
                    <span v-if="item.intervalCount && item.intervalCount > 1">
                    {{
                      $t("onboarding.checkout.months", {
                        interval: item.intervalCount,
                      })
                    }}
                    </span>
                    <span v-else>
                      {{ $t("onboarding.checkout.month") }}
                    </span>
                  </p>
                  <p
                    v-if="item.interval === 'year'"
                    class="text-muted text-center"
                  >
                    {{ $t("onboarding.checkout.year") }}
                  </p>
                  <div
                    v-if="item.allowMultipleDiscounts || discounts.length === 0"
                    :class="showCodeInput ? 'mt-2' : 'mt-3'"
                  >
                    <p
                      v-if="item.allowMultipleDiscounts || discounts.length === 0"
                      class="small mb-25"
                    >
                      {{ $t("onboarding.checkout.form.code") }}
                      <span
                        class="pointer"
                        style="text-decoration: underline"
                        @click="
                          showCodeInput = !showCodeInput;
                          showErrorCode = false;
                        ">
                        {{ $t("onboarding.checkout.form.code-click") }}
                        <feather-icon
                          v-if="!showCodeInput"
                          icon="ChevronDownIcon"
                          size="14"
                        />
                        <feather-icon
                          v-if="showCodeInput"
                          icon="ChevronUpIcon"
                          size="14"
                        />
                      </span>
                    </p>
                    <b-form
                      v-if="showCodeInput"
                      class="d-flex"
                      @submit.stop.prevent="applyCode(code)"
                    >
                      <b-form-input
                        v-model="code"
                        size="sm"
                        autocomplete="off"
                        type="text"
                        :placeholder="
                          $t('onboarding.checkout.form.code-placeholder')
                        "
                      />
                      <b-button
                        class="ml-50"
                        type="submit"
                        variant="primary"
                        size="sm"
                      >
                        {{ $t("onboarding.checkout.form.apply") }}
                      </b-button>
                    </b-form>
                    <div
                      v-for="(discount, index) of discountsToShow"
                      v-show="discountsToShow.length > 0"
                      :key="index"
                      :class="index === 0 ? 'mt-50' : 'mt-25'"
                      class="d-flex justify-content-between"
                    >
                      <p class="text-success mb-0">
                        <feather-icon icon="CheckIcon" size="14" />
                        {{ discount.code.toUpperCase() }}
                        {{ $t("onboarding.checkout.form.apply-success") }}
                      </p>
                      <p
                        class="pointer small mb-0"
                        @click="deleteCode(discount.code)"
                      >
                        <feather-icon icon="Trash2Icon" size="14" />
                        {{ $t("onboarding.checkout.form.delete") }}
                      </p>
                    </div>
                    <div
                      v-if="showErrorCode"
                      class="d-flex justify-content-between"
                    >
                      <p class="text-danger mb-0">
                        <feather-icon icon="XIcon" size="14" />
                        {{ $t("onboarding.checkout.form.apply-error") }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <!-- User data -->
          <b-col cols="12" :lg="item.total !== 0 ? '4' : '6'">
            <b-card class="general-card">
              <b-card-body>
                <div>
                  <!-- Logged User -->
                  <h4 class="mb-2">
                    2. {{ $t("onboarding.checkout.about-user") }}
                  </h4>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('onboarding.checkout.form.name')"
                        label-for="loggedUser name"
                      >
                        <b-form-input
                          id="loggedUser name"
                          :value="loggedUser.name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('onboarding.checkout.form.surname')"
                        label-for="loggedUser surname"
                      >
                        <b-form-input
                          id="loggedUser surname"
                          :value="loggedUser.surname"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mt-1">
                      <b-form-group
                        :label="$t('onboarding.checkout.form.email')"
                        label-for="loggedUser email"
                      >
                        <b-form-input
                          id="loggedUser email"
                          :value="loggedUser.email"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <!-- Payment methods -->
          <b-col v-if="item.total !== 0" cols="12" lg="4">
            <b-card class="general-card">
              <b-card-body>
                <div>
                  <h4 class="mb-2">
                    3. {{ $t("onboarding.checkout.form.payment") }}
                  </h4>
                  <b-form-group>
                    <b-form-checkbox-group
                      v-if="item.interval"
                      id="paymentOneTime"
                      v-model="selectedPaymentMethod"
                      class="mx-2 mt-1 d-flex"
                    >
                      <!-- stripe -->
                      <b-col
                        cols="6"
                        class="align-items-center justify-content-center d-flex"
                      >
                        <b-card
                          no-body
                          class="d-flex flex-column align-items-center justify-content-center px-50 py-75 mb-0 card-payment border-radius-8"
                          :border-variant="
                            paymentTypeOneTime === 'stripe'
                              ? 'primary'
                              : 'secondary'
                          "
                          for="stripe"
                          @click="paymentTypeOneTime = 'stripe'"
                        >
                          <b-form-checkbox
                            v-if="paymentTypeOneTime === 'stripe'"
                            id="stripe"
                            class="check-payment"
                            value="stripe"
                          />
                          <b-icon-credit-card
                            font-size="26"
                            class="text-center mb-25"
                            :class="
                              paymentTypeOneTime === 'stripe'
                                ? 'text-primary'
                                : 'text-secondary'
                            "
                          />
                          <span
                            class="font-small-2"
                            :class="
                              paymentTypeOneTime === 'stripe'
                                ? 'text-primary'
                                : 'text-secondary'
                            "
                          >
                            {{ $t("onboarding.checkout.form.credit-card") }}
                          </span>
                        </b-card>
                      </b-col>
                      <!-- CMPay -->
                      <b-col
                        v-if="isMedellin"
                        cols="6"
                        class="align-items-center justify-content-center d-flex"
                      >
                        <b-card
                          no-body
                          class="d-flex flex-column align-items-center justify-content-center px-50 py-75 mb-0 card-payment border-radius-8"
                          :border-variant="
                            paymentTypeOneTime === 'cmpay'
                              ? 'primary'
                              : 'secondary'
                          "
                          for="cmpay"
                          @click="paymentTypeOneTime = 'cmpay'"
                        >
                          <b-form-checkbox
                            v-if="paymentTypeOneTime === 'cmpay'"
                            id="cmpay"
                            class="check-payment"
                            value="cmpay"
                          />
                          <b-icon-credit-card
                            font-size="26"
                            class="text-center mb-25"
                            :class="
                              paymentTypeOneTime === 'cmpay'
                                ? 'text-primary'
                                : 'text-secondary'
                            "
                          />
                          <span
                            class="font-small-2"
                            :class="
                              paymentTypeOneTime === 'cmpay'
                                ? 'text-primary'
                                : 'text-secondary'
                            "
                          >
                            CM Pay
                          </span>
                        </b-card>
                      </b-col>
                    </b-form-checkbox-group>
                    <b-form-checkbox-group
                      v-else
                      id="payment"
                      v-model="paymentType"
                      required
                      class="mx-2 mt-1 d-flex"
                    >
                      <!-- Redsys -->
                      <b-col
                        v-if="!isMedellin"
                        cols="6"
                        class="align-items-center justify-content-center d-flex"
                      >
                        <b-card
                          no-body
                          class="d-flex flex-column align-items-center justify-content-center px-50 py-75 mb-0 card-payment border-radius-8"
                          :border-variant="
                            paymentType === 'redsys' ? 'primary' : 'secondary'
                          "
                          for="redsys"
                          @click="paymentType = 'redsys'"
                        >
                          <b-form-checkbox
                            v-if="paymentType === 'redsys'"
                            id="redsys"
                            class="check-payment"
                            value="redsys"
                          />
                          <b-icon-credit-card
                            font-size="26"
                            class="text-center mb-25"
                            :class="
                              paymentType === 'redsys'
                                ? 'text-primary'
                                : 'text-secondary'
                            "
                          />
                          <span
                            class="font-small-2"
                            :class="
                              paymentType === 'redsys'
                                ? 'text-primary'
                                : 'text-secondary'
                            "
                            >{{ $t("onboarding.checkout.form.credit-card") }}
                          </span>
                        </b-card>
                      </b-col>
                      <!-- CMPay -->
                      <b-col
                        v-else
                        cols="6"
                        class="align-items-center justify-content-center d-flex"
                      >
                        <b-card
                          no-body
                          class="d-flex flex-column align-items-center justify-content-center px-50 py-75 mb-0 card-payment border-radius-8"
                          :border-variant="
                            paymentType === 'cmpay' ? 'primary' : 'secondary'
                          "
                          for="cmpay"
                          @click="paymentType = 'cmpay'"
                        >
                          <b-form-checkbox
                            v-if="paymentType === 'cmpay'"
                            id="cmpay"
                            class="check-payment"
                            value="cmpay"
                          />
                          <b-icon-credit-card
                            font-size="26"
                            class="text-center mb-25"
                            :class="
                              paymentType === 'cmpay'
                                ? 'text-primary'
                                : 'text-secondary'
                            "
                          />
                          <span
                            class="font-small-2"
                            :class="
                              paymentType === 'cmpay'
                                ? 'text-primary'
                                : 'text-secondary'
                            "
                            >CM Pay
                          </span>
                        </b-card>
                      </b-col>
                      <!-- Pay Pal -->
                      <!-- <b-col cols="6" class="align-items-center justify-content-center d-flex">
                        <b-card
                          no-body
                          class="d-flex flex-column align-items-center justify-content-center px-50 mb-0 py-75 card-payment border-radius-8
"
                          :border-variant="paymentType === 'paypal' ? 'primary' : 'secondary'"
                          for="paypal"
                          @click="paymentType = 'paypal'"
                        >
                          <b-form-checkbox
                            v-if="paymentType === 'paypal'"
                            id="paypal"
                            class="check-payment"
                            value="paypal"
                          />
                          <div class="px-1 mb-25 mt-25">
                            <b-img
                              v-if="paymentType === 'paypal'"
                              fluid
                              src="../src/assets/images/placeholders/light/paypal-color.svg"
                              alt="Paypal"
                            />
                            <b-img
                              v-else
                              fluid
                              src="../src/assets/images/placeholders/light/paypal-bn.svg"
                              alt="Paypal"
                            />
                          </div>
                          <span
                            class="font-small-2"
                            :class="paymentType === 'paypal' ? 'text-primary' : 'text-secondary'"
                          >{{ $t('onboarding.checkout.form.paypal') }}
                          </span>
                        </b-card>
                      </b-col> -->
                    </b-form-checkbox-group>
                  </b-form-group>
                  <b-row>
                    <div class="cards-div">
                      <img :src="cardsImagesNoPaypal" class="cards-images">
                    </div>
                  </b-row>
                </div>
              </b-card-body>
            </b-card>
          </b-col>

          <!-- Datos de Facturación -->
          <b-col v-if="!isMedellin" cols="12">
            <div class="h-100 d-flex ml-75">
              <p class="title mr-2">
                {{ $t("onboarding.checkout.need-bill") }}
              </p>
              <div class="d-flex">
                <p class="mr-50">
                  {{ $t("no") }}
                </p>
                <b-form-checkbox v-model="billNeeded" switch>
                  {{ $t("onboarding.checkout.yes") }}
                </b-form-checkbox>
              </div>
            </div>
          </b-col>

          <!-- Factura -->
          <b-col v-if="item && billNeeded" cols="12" lg="12">
            <b-card no-body class="general-card">
              <b-card-body>
                <div class="p-2">
                  <div class="w-100 justify-content-between align-items-center">
                    <h4 class="mb-50">
                      <span v-if="item.total !== 0">
                        4.
                      </span>
                      <span v-else>
                        3.
                      </span>
                      {{ $t("onboarding.checkout.bill") }}
                    </h4>
                  </div>

                  <!-- Owner type -->
                  <div
                    class="d-flex justify-content-around mt-1 mb-2 flex-wrap"
                  >
                    <a
                      class="owner-background d-block mx-auto mt-50"
                      :class="{ 'owner-background--active': isPerson }"
                      @click="getTypeOwner()"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="20"
                        class="d-block mx-auto"
                        :class="isPerson ? 'text-primary' : 'text-muted'"
                      />
                      <small
                        class="mt-50"
                        :class="isPerson ? 'text-primary' : 'text-muted'"
                      >
                        {{ $t("onboarding.checkout.individual") }}
                      </small>
                    </a>
                    <a
                      class="owner-background d-block mx-auto mt-50"
                      :class="{ 'owner-background--active': isOrganization }"
                      @click="getTypeOwner()"
                    >
                      <feather-icon
                        icon="BriefcaseIcon"
                        size="20"
                        class="d-block mx-auto"
                        :class="isOrganization ? 'text-primary' : 'text-muted'"
                      />
                      <small
                        class="mt-50"
                        :class="isOrganization ? 'text-primary' : 'text-muted'"
                      >
                        {{ $t("onboarding.checkout.business") }}
                      </small>
                    </a>
                  </div>

                  <!-- Data to send -->
                  <b-row>
                    <!-- Name -->
                    <b-col cols="6" sm="12" lg="6">
                      <validation-provider
                        #default="validationContext"
                        name=" "
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('onboarding.checkout.form.name')"
                          label-for="name"
                        >
                          <b-form-input
                            id="name"
                            v-model="dataToSend.name"
                            required
                            type="text"
                            :disabled="disabled"
                            rules="required"
                            :placeholder="
                              $t('onboarding.checkout.form.name-placeholder')
                            "
                            :state="getValidationState(validationContext)"
                            aria-describedby="dataToSend-name"
                          />
                          <b-form-invalid-feedback id="dataToSend-name">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Surname -->
                    <b-col cols="6" sm="12" lg="6">
                      <validation-provider
                        #default="validationContext"
                        name=" "
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('onboarding.checkout.form.surname')"
                          label-for="surname"
                        >
                          <b-form-input
                            id="surname"
                            v-model="dataToSend.surname"
                            type="text"
                            required
                            rules="required"
                            :disabled="disabled"
                            :placeholder="
                              $t('onboarding.checkout.form.surname-placeholder')
                            "
                            :state="getValidationState(validationContext)"
                            aria-describedby="dataToSend-surname"
                          />
                          <b-form-invalid-feedback id="dataToSend-surname">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Email -->
                    <b-col cols="6" sm="12" lg="6">
                      <validation-provider
                        #default="validationContext"
                        name=" "
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('onboarding.checkout.form.email')"
                          label-for="email"
                        >
                          <b-form-input
                            id="email"
                            v-model="dataToSend.email"
                            type="email"
                            required
                            rules="required"
                            :disabled="disabled"
                            :placeholder="
                              $t('onboarding.checkout.form.email-placeholder')
                            "
                            :state="getValidationState(validationContext)"
                            aria-describedby="dataToSend-email"
                          />
                          <b-form-invalid-feedback id="dataToSend-email">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Phone -->
                    <b-col cols="6" sm="12" lg="6">
                      <validation-provider
                        #default="validationContext"
                        name=" "
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('onboarding.checkout.form.phone')"
                          label-for="phone"
                        >
                          <b-form-input
                            id="phone"
                            v-model="dataToSend.phone"
                            type="phone"
                            required
                            rules="required"
                            :disabled="disabled"
                            :placeholder="
                              $t('onboarding.checkout.form.phone-placeholder')
                            "
                            :state="getValidationState(validationContext)"
                            aria-describedby="dataToSend-phone"
                          />
                          <b-form-invalid-feedback id="dataToSend-phone">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Company -->
                    <b-col v-if="isOrganization" cols="12" sm="12" lg="6">
                      <validation-provider
                        #default="validationContext"
                        name=" "
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('onboarding.checkout.form.company')"
                          label-for="company"
                        >
                          <b-form-input
                            id="company"
                            v-model="dataToSend.company"
                            type="text"
                            :disabled="disabled"
                            rules="required"
                            placeholder="Escribe aquí el nombre de la compañía..."
                            :state="
                              isOrganization
                                ? getValidationState(validationContext)
                                : ''
                            "
                            aria-describedby="dataToSend-company"
                          />
                          <b-form-invalid-feedback
                            id="dataToSend-company"
                            class="mb-0"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- DNI o Pasaporte -->
                    <b-col v-if="!isOrganization" cols="12" sm="12" lg="6">
                      <validation-provider
                        #default="validationContext"
                        name=" "
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('onboarding.checkout.form.dni')"
                          label-for="dni"
                        >
                          <b-form-input
                            id="dni"
                            v-model="dataToSend.cif"
                            type="text"
                            :disabled="disabled"
                            rules="required"
                            :placeholder="
                              $t('onboarding.checkout.form.dni-placeholder')
                            "
                            :state="
                              isOrganization
                                ? getValidationState(validationContext)
                                : ''
                            "
                            aria-describedby="dataToSend-cif"
                          />
                          <b-form-invalid-feedback id="dataToSend-cif">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- NIF CIF -->
                    <b-col v-if="isOrganization" cols="6" sm="12" lg="6">
                      <validation-provider
                        #default="validationContext"
                        name=" "
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('onboarding.checkout.form.cif')"
                          label-for="cif"
                        >
                          <b-form-input
                            id="cif"
                            v-model="dataToSend.cif"
                            type="text"
                            :disabled="disabled"
                            rules="required"
                            :placeholder="
                              $t('onboarding.checkout.form.cif-placeholder')
                            "
                            :state="
                              isOrganization
                                ? getValidationState(validationContext)
                                : ''
                            "
                            aria-describedby="dataToSend-cif"
                          />
                          <b-form-invalid-feedback id="dataToSend-cif">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Address -->
                    <b-col cols="12" sm="12" lg="6">
                      <b-form-group
                        :label="$t('onboarding.checkout.form.address')"
                        label-for="address"
                      >
                        <b-form-input
                          id="address"
                          v-model="dataToSend.address"
                          type="text"
                          :disabled="disabled"
                          :placeholder="
                            $t('onboarding.checkout.form.address-placeholder')
                          "
                        />
                      </b-form-group>
                    </b-col>
                    <!-- City -->
                    <b-col cols="6" sm="12" lg="6">
                      <b-form-group
                        :label="$t('onboarding.checkout.form.city')"
                        label-for="city"
                      >
                        <b-form-input
                          id="city"
                          v-model="dataToSend.city"
                          type="text"
                          :disabled="disabled"
                          :placeholder="
                            $t('onboarding.checkout.form.city-placeholder')
                          "
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Postal code -->
                    <b-col cols="6" sm="12" lg="6">
                      <b-form-group
                        :label="$t('onboarding.checkout.form.postal')"
                        label-for="postal"
                      >
                        <b-form-input
                          id="postal"
                          v-model="dataToSend.postal"
                          type="text"
                          :disabled="disabled"
                          :placeholder="
                            $t('onboarding.checkout.form.postal-placeholder')
                          "
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Province -->
                    <b-col cols="6" sm="12" lg="6">
                      <b-form-group
                        :label="$t('onboarding.checkout.form.province')"
                        label-for="province"
                      >
                        <b-form-input
                          id="province"
                          v-model="dataToSend.province"
                          type="text"
                          :disabled="disabled"
                          :placeholder="
                            $t('onboarding.checkout.form.province-placeholder')
                          "
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Country-->
                    <b-col cols="6" sm="12" lg="6">
                      <b-form-group
                        :label="$t('onboarding.checkout.form.country')"
                        label-for="country"
                      >
                        <b-form-input
                          id="country"
                          v-model="dataToSend.country"
                          type="text"
                          :disabled="disabled"
                          :placeholder="
                            $t('onboarding.checkout.form.country-placeholder')
                          "
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- REDSYS form -->
    <form v-if="!isMedellin"
      id="redsysForm"
      :action="redSysURL"
      method="POST"
      style="visibility: hidden"
      target="_blank"
    >
      <input
        type="text"
        name="Ds_MerchantParameters"
        :value="redsysData.Ds_MerchantParameters"
        hidden
      />
      <input
        type="text"
        name="Ds_Signature"
        :value="redsysData.Ds_Signature"
        hidden
      />
      <input
        type="text"
        name="Ds_SignatureVersion"
        :value="redsysData.Ds_SignatureVersion"
        hidden
      />
      <input id="submitRedsys" type="submit" />
    </form>
  </div>
</template>

<script>
import UserAvatar from "@core/components/user/UserAvatar.vue";
import BgHeaderContainer from "@core/components/bg/BgHeaderContainer.vue";
//import { getImageResource } from "@/@core/utils/image-utils";
import CustomfieldsModal from "@/@core/components/modal/CustomfieldsModal.vue";
import ClassifiersModal from "@/@core/components/modal/ClassifiersModal.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import Vue from "vue";
import TextResizable from "@/backoffice/components/TextResizable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import {
  calculTotalPrice,
  fractionPrice,
} from "@/store/collective/plans-helpers";
import CardsImagesNoPaypal from "@/assets/images/icons/cards_full_no_paypal.png";

export default {
  name: "OrderPurchase",
  components: {
    UserAvatar,
    ClassifiersModal,
    CustomfieldsModal,
    BgHeaderContainer,
    TextResizable,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: true,
      methods: [],
      billNeeded: false,
      isPerson: true,
      isOrganization: false,
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      newComment: "",
      nextStep: false,
      required,
      redsysData: {},
      disabled: false,
      disabledButtons: true,
      selectedAnswer: {},
      imageSrc: null,
      imageForum: null,
      paymentType: null,
      paymentTypeOneTime: null,
      selectedPaymentMethod: [],
      code: "",
      showCodeInput: false,
      showErrorCode: false,
      discounts: [],
      discountsToShow: [],
      automaticDiscount: null,
      total: 0,
      forum: { name: "", description: "" },
      //cardsImagesNoPaypal: CardsImagesNoPaypal,
    };
  },
  computed: {
    dataToSend() {
      if (this.loggedUser) {
        return {
          name: this.loggedUser.name,
          surname: this.loggedUser.surname,
          email: this.loggedUser.email,
          phone: "",
          city: "",
          company: "",
          cif: "",
          province: "",
          address: "",
          postal: "",
          country: "",
        };
      }
      return {
        name: "",
        surname: "",
        email: "",
        phone: "",
        city: "",
        company: "",
        cif: "",
        province: "",
        address: "",
        postal: "",
        country: "",
      };
    },
    id() {
      return this.$route.params.id;
    },
    token() {
      return Vue.$cookies.get("accessToken");
    },
    type() {
      return this.$route.params.type;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    item() {
      return this.$store.getters.item("subscriptionPlans", this.id) || null;
    },
    discountKey() {
      return this.$route.query.discount;
    },
    price() {
      if (this.item.total === 0 || this.item.total == null) {
        return this.$t("onboarding.checkout.free");
      }
      this.total = this.item.total;
      if (this.discounts) {
        this.discounts.forEach(
          (discount) => (this.total = calculTotalPrice(this.total, discount))
        );
      }
      if (this.total <= 0) {
        return this.$t("onboarding.checkout.free");
      }
      return fractionPrice(this.total, this.item.currencySymbol);
    },
    redSysURL() {
      if (this.item.isTest) {
        return process.env.VUE_APP_PAY_REDSYS_TEST;
      }
      return process.env.VUE_APP_PAY_REDSYS;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    currentSpace() {
      return this.$store.getters.currentCollective;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    isMedellin() {
       // tu-comunidad, pruebasccma
      return this.currentSpace.communityMain === 'e1326b70-dc13-11ec-9877-3b619755c76d' ||
        this.currentSpace.key === 'e1326b70-dc13-11ec-9877-3b619755c76d' ||
        this.currentSpace.communityMain === '1838b97b-2438-4e65-ba31-1e40c8881a42' ||
        this.currentSpace.key === '1838b97b-2438-4e65-ba31-1e40c8881a42';
    },
    cardsImagesNoPaypal() {
      return CardsImagesNoPaypal;
    },
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return { getValidationState };
  },

  async created() {
    this.isLoading = true;
    await this.$store.dispatch("initCurrentCommunity", {
      collective: this.currentSpace,
    });
    await this.fetchData();
    document.getElementsByClassName("content-wrapper")[0].style.marginTop =
      "0px";
    if (this.item) {
      this.total = this.item.total;
    }
    if (this.isMedellin) {
      this.paymentTypeOneTime = "cmpay";
      this.paymentType = "cmpay";
    }
    // else if (this.item.interval == null) {
    //   this.paymentType = "redsys";
    // }
    else {
      this.paymentType = "stripe";
      this.paymentTypeOneTime = "redsys";
    }
    if (this.item && this.discountKey) {
      await this.checkAutomaticDiscount();
    }
    this.isLoading = false;
  },
  async mounted() {
    if (Object.values(this.currentSpace).length < 1) {
      this.$store.dispatch("initCurrentCommunity", {
        communitySlug: this.communitySlug,
      });
    }
  },
  methods: {
    deleteCode(code) {
      const index = this.discounts.findIndex(
        (discount) => discount.code === code
      );
      this.discounts.splice(index, 1);
      this.discountsToShow.splice(index - 1, 1);
    },
    async checkAutomaticDiscount() {
      await this.applyCode(null, this.discountKey, true);
    },
    async applyCode(code, couponKey, isAutomatic) {
      try {
        const response = await this.$store.dispatch("getItems", {
          noSet: true,
          itemType: "coupons",
          forceAPICall: true,
          statusNeeded: true,
          perPage: 1000,
          requestConfig: {
            code: code ? code.toUpperCase() : null,
            couponKey,
            communitySlug: this.communitySlug,
          },
        });
        if (response.data) {
          this.discounts = [...this.discounts, ...response.data];
          this.code = "";

          if (!isAutomatic) {
            this.discountsToShow = [...this.discountsToShow, ...response.data];
          }
        } else if (!isAutomatic) {
          this.showErrorCode = true;
          this.code = "";
        }
        this.showCodeInput = false;
      } catch (error) {
        this.notifyError(this.$t("error-message.load-failed"));
      }
    },
    async handleInput(locale, localeValue) {
      this.$emit("input", { ...this.item, [locale]: localeValue });
    },
    onEnter() {
      this.$emit("input", [...this.info]);
    },
    validationRequirement(input) {
      return input.length > 0;
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    async fetchData() {
      if (this.type === "community") {
        this.methods.push("subscription");
        this.methods.push("unique");
        await this.$store.dispatch("getItemsPayments", {
          itemType: "subscriptionPlans",
          requestConfig: {
            communitySlug: this.communitySlug,
            orderBy: -1,
          },
        });
      }
    },
    async processPayment() {
      console.log('Processing payment...');
      let price = this.item.total;
      console.log('Price:', price);
      console.log('Total:', this.total);
      let response;
      if (parseInt(price) !== 0 && parseInt(this.total) !== 0) {
        console.log('Price and total are not 0');
        console.log('Payment method:', this.paymentType);
        console.log('Payment method (one time):', this.paymentTypeOneTime);
        if (this.paymentTypeOneTime === "cmpay" || this.paymentType === "cmpay") {
          //CMpay
          response = await this.callPaymentMethod("cmpay");
        } else if (this.paymentTypeOneTime === "stripe" || this.paymentType === "stripe") {
          //Stripe
          response = await this.callPaymentMethod("stripe");
        } else if(this.paymentTypeOneTime === "paypal" || this.paymentType === "paypal") {
          //Paypal
            response = await this.callPaymentMethod("v1/paypal");
        } else if (this.paymentTypeOneTime === "redsys" || this.paymentType === "redsys") {
          //Redsys
          response = await this.callPaymentMethod("v1/redsys");
          this.redsysData = response;
          for (const row of Object.keys(response)) {
            document.getElementsByName(row)[0].value = response[row];
          }
          document.getElementById("redsysForm").submit();
          return;
        } else {
          console.log('Unknown payment method!');
        }
      } else if (this.total !== 0) {
        console.log('Total is 0');
        if (this.paymentTypeOneTime === "stripe") {
          response = await this.callPaymentMethod("stripe");
        }
      } else {
        await this.callPaymentMethod("subscriptions");

        return this.$router.replace({
          name: "onboarding-success",
          params: { 
            communityId: this.communitySlug, 
            subscriptionPlanId: this.item.key 
          },
        });
      }
      console.log('Payment service response:', response);
      if(response){
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = response;
        a.target = "_blank";
        a.click();
        document.body.removeChild(a);
      }
    },
    async callPaymentMethod(itemType) {
      console.log('Calling payment method...');
      const discountCodes = [];
      if (this.discounts.length > 0) {
        this.discounts.forEach((discount) => discountCodes.push(discount.key));
      }
      if (this.total) {
        this.total = +this.total.toFixed(2);
      }

      const dataToSend = {
          ...this.dataToSend,
          priceKey: this.item.priceKey,
          price: this.total,
          discountCode: discountCodes,
          communitySlug: this.communitySlug,
          locale: this.currentLocale,
          billNeeded: this.billNeeded,
          paymentType: this.paymentType,
          paymentTypeOneTime: this.paymentTypeOneTime
        };
      console.log('Sending next data to BE:', dataToSend);
      const response = await this.$store.dispatch("processPayment", {
        itemType,
        data: dataToSend,
      });
      return response;
    },
    getTypeOwner() {
      this.isOrganization = !this.isOrganization;
      this.isPerson = !this.isPerson;
    },

    goBackSubscriptions() {
      return this.$router.replace({
        name: "onboarding-plans",
        params: { communityId: this.communitySlug },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.price {
  font-size: 3.2rem;
}
.general-card {
  min-height: 20.8rem;
}
.card-payment {
  width: 106px;
  min-width: 106px;
  height: 69px;
}
.check-payment {
  position: absolute;
  top: -6px;
  left: -5px;
}

.cards-images {
  height: 2em;
  margin-top: 20px;
}

.cards-div {
  width: 100%;
  text-align: center;
}
</style>
